import { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Listbox, Popover } from "@headlessui/react";
import { FaDatabase, FaEllipsisV, FaEthereum, FaExclamationTriangle, FaGasPump, FaQuestion, FaRegCopy, FaSave } from "react-icons/fa";
import { IoIosAddCircle, IoIosArrowDown, IoIosClose, IoIosDownload, IoIosRefresh } from "react-icons/io";
import { useAccount, useChainId } from "wagmi";
import { ethers } from "ethers";
import axios from "axios";
import BigNumber from "bignumber.js";

import { AppContext } from "../App";
import ZombieDialog from "../components/Dialogs/ZombieDialog";
import NewWalletDialog from "../components/Dialogs/NewWalletDialog";
import TokenAmountDialog from "../components/Dialogs/TokenAmountDialog";
import EthAmountDialog from "../components/Dialogs/EthAmountDialog";
import { useEthersSigner, useEthersProvider } from "../utils/provider";
import { ellipsisAddress, isValidAddress } from "../utils/methods";
import { TEMPLATES } from "../utils/constants";
import goliveABI from "../abi/IGoLive.json";
import tokenABI from "../abi/ITradingToken.json";

import MySidebar1 from "./MySidebar1";
import MySidebar2 from "./MySidebar2";

// const ENABLE_MODES = [
//     "Sign with Owner's Wallet",
//     "Use Owner's Private Key",
// ];

export default function VolumeBot({ className }) {
    const {
        SERVER_URL,
        setLoadingPrompt,
        setOpenLoading,
        user,
        currentVolume,
        setCurrentVolume,
        updateVolume,
        volumeWalletBalanceData,
        notifyStatus,
        setNotifyStatus,
        updateVolumeBalances,
    } = useContext(AppContext);
    const chainId = useChainId();
    const { isConnected } = useAccount();

    const [copied, setCopied] = useState({});
    const [targetWallet, setTargetWallet] = useState("");
    const [zombieDialog, setZombieDialog] = useState(false);
    const [zombieIndex, setZombieIndex] = useState(0);
    const [newWalletDialog, setNewWalletDialog] = useState(false);
    const [tokenAmountDialog, setTokenAmountDialog] = useState(false);
    const [ethAmountDialog, setEthAmountDialog] = useState(false);
    const [gasPrice, setGasPrice] = useState("0");
    const [gasPriceMultiplier, setGasPriceMultiplier] = useState("");

    const [token, setToken] = useState("");
    const [tokenInfo, setTokenInfo] = useState({ decimals: "", totalSupply: "" });
    const [zombieWallet, setZombieWallet] = useState({ address: "", privateKey: "" });
    const [deadBlocks, setDeadBlocks] = useState("2");
    const [walletAllChecked, setWalletAllChecked] = useState(false);
    const [walletChecked, setWalletChecked] = useState([]);

    const [walletEthBalance, setWalletEthBalance] = useState([]);
    const [walletTokenBalance, setWalletTokenBalance] = useState([]);
    const [walletTokenAmount, setWalletTokenAmount] = useState([]);
    const [walletEthAmount, setWalletEthAmount] = useState([]);

    const [teamWalletAllChecked, setTeamWalletAllChecked] = useState(false);
    const [teamWalletChecked, setTeamWalletChecked] = useState([]);
    const [teamWalletEthBalance, setTeamWalletEthBalance] = useState([]);
    const [teamWalletTokenBalance, setTeamWalletTokenBalance] = useState([]);
    const [teamWalletTokenAmount, setTeamWalletTokenAmount] = useState([]);
    const [isDragging, setIsDragging] = useState(false);

    const [ethToDisperse, setEthToDisperse] = useState(0);

    const [runningStatus, setRunningStatus] = useState("ready");

    const provider = useEthersProvider(chainId);
    const signer = useEthersSigner(chainId);
    const disabled = false;

    useEffect(() => {
        if (currentVolume.token || currentVolume.zombies) {

            setToken(currentVolume.token.address);
            setZombieWallet({
                address: currentVolume.zombie,
                privateKey: "",
            });
        }
        else {
            setToken("");
            setZombieWallet({ address: "", privateKey: "" });
            setWalletAllChecked(false);
            setWalletChecked([]);
        }
    }, [currentVolume.token, currentVolume.zombie]);

    useEffect(() => {
        const getTokenInfo = async (token, provider) => {
            try {
                console.log("Getting token info...", token);
                const tokenContract = new ethers.Contract(token, tokenABI, provider);
                const decimals = await tokenContract.decimals();
                const totalSupply = await tokenContract.totalSupply();
                setTokenInfo({
                    decimals: decimals.toString(),
                    totalSupply: new BigNumber(totalSupply.toString() + "e-" + decimals.toString()).toFixed(0)
                });
            }
            catch (err) {
                console.log(err);
                setTokenInfo({
                    decimals: "",
                    totalSupply: "",
                });
            }
        }
        if (isValidAddress(token)) {
            getTokenInfo(token, provider);
        }
        else {
            setTokenInfo({
                decimals: "",
                totalSupply: "",
            });
        }
    }, [token, provider]);

    useEffect(() => {
        if (currentVolume.wallets) {
            if (currentVolume.wallets.length !== walletChecked.length) {
                const newWalletChecked = currentVolume.wallets.map(() => false);
                setWalletChecked(newWalletChecked);
                setWalletAllChecked(false);
            }

            setWalletEthBalance(currentVolume.wallets.map(() => "-"));
            setWalletTokenBalance(currentVolume.wallets.map(() => "0"));
            setWalletTokenAmount(currentVolume.wallets.map((item) => "0"));
            setWalletEthAmount(currentVolume.wallets.map(item => item.initialEthAmount));
        }
        else {
            setWalletEthBalance([]);
            setWalletTokenBalance([]);
            setWalletTokenAmount([]);
            setWalletEthAmount([]);
        }
    }, [currentVolume.wallets, walletChecked.length]);

    useEffect(() => {
        if (currentVolume.token && volumeWalletBalanceData.address === currentVolume.token.address && volumeWalletBalanceData.token.length === walletTokenBalance.length) {
            setWalletTokenBalance(volumeWalletBalanceData.token);
        }
    }, [currentVolume.token, volumeWalletBalanceData.address, volumeWalletBalanceData.token, walletTokenBalance.length]);

    useEffect(() => {
        if (currentVolume.token && volumeWalletBalanceData.address === currentVolume.token.address && volumeWalletBalanceData.eth.length === walletEthBalance.length) {
            setWalletEthBalance(volumeWalletBalanceData.eth);
        }
    }, [currentVolume.token, volumeWalletBalanceData.address, volumeWalletBalanceData.eth, walletEthBalance.length]);

    useEffect(() => {
        if (notifyStatus.tag === "VOLUME_BUY_COMPLETED") {
            if (notifyStatus.success)
                toast.success("Succeed to Buy ETH!");
            else
                toast.warn("Failed to Buy ETH!");

            if (notifyStatus.volume) {
                updateVolume(notifyStatus.volume);
                if (currentVolume._id === notifyStatus.volume._id) {
                    setCurrentVolume(notifyStatus.volume);
                }
            }
            const _wallets = currentVolume.wallets.map((item) => item.address);
            updateVolumeBalances(provider, token, _wallets);

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        } else if (notifyStatus.tag === "VOLUME_SELL_COMPLETED") {
            if (notifyStatus.success)
                toast.success("Succeed to sell!");
            else
                toast.warn("Failed to sell!");

            if (notifyStatus.volume) {
                updateVolume(notifyStatus.volume);
                if (currentVolume._id === notifyStatus.volume._id)
                    setCurrentVolume(notifyStatus.volume);
            }

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        } else if (notifyStatus.tag === "VOLUME_COLLECT_ALL_ETH") {
            if (notifyStatus.success)
                toast.success("Succeed to collect all ETH!");
            else
                toast.warn("Failed to collect all ETH!");

            if (notifyStatus.project) {
                updateVolume(notifyStatus.volume);
                if (currentVolume._id === notifyStatus.volume._id)
                    setCurrentVolume(notifyStatus.volume);
            }

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        } else if (notifyStatus.tag === "VOLUME_DISPERSE_COMPLETED") {
            if (notifyStatus.success)
                toast.success("Succeed to Disperse ETH!");
            else
                toast.warn("Failed to DisperseETH!");

            if (notifyStatus.volume) {
                updateVolume(notifyStatus.volume);
                if (currentVolume._id === notifyStatus.volume._id) {
                    setCurrentVolume(notifyStatus.volume);
                }
            }
            const _wallets = currentVolume.wallets.map((item) => item.address);
            updateVolumeBalances(provider, token, _wallets);

            setOpenLoading(false);
            setNotifyStatus({ success: true, tag: "NONE" });
        }
    }, [notifyStatus, currentVolume._id]);

    const handleMouseDown = (e, id) => {
        e.preventDefault();
        setIsDragging(true);
        handleWalletChanged(id, "checked", !walletChecked[id])
    };

    const handleMouseEnter = (id) => {
        if (isDragging) {
            handleWalletChanged(id, "checked", !walletChecked[id])
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const copyToClipboard = async (key, text) => {
        if ('clipboard' in navigator) {
            await navigator.clipboard.writeText(text);
            toast.success("Copied");
            setCopied({
                ...copied,
                [key]: true,
            });
            setTimeout(() => setCopied({
                ...copied,
                [key]: false,
            }), 2000);
        }
        else
            console.error('Clipboard not supported');
    };

    const getSelectedTokenBalance = () => {
        try {
            let selectedBalance = 0;
            for (let i = 0; i < walletChecked.length; i++) {
                if (!walletChecked[i])
                    continue;

                selectedBalance += Number(walletTokenBalance[i]);
            }
            return selectedBalance.toFixed(4);
        }
        catch (err) {
            console.log(err);
        }
        return 0;
    };

    const getSelectedTokensToBuy = () => {
        try {
            let selectedBalance = 0;
            for (let i = 0; i < walletChecked.length; i++) {
                if (!walletChecked[i])
                    continue;

                selectedBalance += Number(walletTokenAmount[i]);
            }
            return selectedBalance.toFixed(4);
        }
        catch (err) {
            console.log(err);
        }
        return 0;
    };

    const handleQueryGasPrice = async () => {
        if (isConnected) {
            setLoadingPrompt("Querying gas price...");
            setOpenLoading(true);
            try {
                const feeData = await provider.getFeeData();
                setGasPrice(Number(new BigNumber(feeData.gasPrice.toString() + "e-9")).toFixed(1));
                console.log("gasPrice:", feeData.gasPrice, "maxFeePerGas:", feeData.maxFeePerGas, "maxPriorityFeePerGas:", feeData.maxPriorityFeePerGas);
            }
            catch (err) {
                setGasPrice("0");
            }
            setOpenLoading(false);
        }
    };

    const handleSaveVolume = async (_zombieWallet = { address: "", privateKey: "" }) => {
        setLoadingPrompt("Saving volume...");
        setOpenLoading(true);

        console.log("glory", _zombieWallet);
        const wallets = currentVolume.wallets.map((item, index) => {
            return {
                address: item.address,
                initialEthAmount: walletEthAmount[index],
            };
        });

        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/volume/save`,
                {
                    volumeId: currentVolume._id,
                    chainId: chainId,
                    token: token,
                    zombie: _zombieWallet,
                    wallets: wallets
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );

            if (data.success) {
                updateVolume(data.volume);
                if (currentVolume._id === data.volume._id)
                    setCurrentVolume(data.volume);
                toast.success("Volume has been saved successfully");
            }
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to save volume info");
        }
        setOpenLoading(false);
    };

    const handleOKZombiePrivateKey = (key) => {
        try {
            const wallet = new ethers.Wallet(key);
            setZombieWallet({ address: wallet.address, privateKey: key });
            handleSaveVolume({ address: wallet.address, privateKey: key });
        }
        catch (err) {
            console.log(err);
            toast.warn("Invalid private key!");
        }

        setZombieDialog(false);
    };

    const handleSetZombieWallet = () => {
        setZombieDialog(true);
    };

    const handleOKNewWallet = async (walletCount, fresh) => {
        let count = 0;
        try {
            count = parseInt(walletCount);
        }
        catch (err) {
            console.log(err);
        }

        if (isNaN(count) || count < 0 || count > 90) {
            toast.warn("Invalid wallet count, wallet count must be in the range 1-90");
            return;
        }

        setNewWalletDialog(false);
        setLoadingPrompt("Generating new wallets...");
        setOpenLoading(true);
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/volume/generate-wallets`,
                {
                    volumeId: currentVolume._id,
                    count: walletCount,
                    fresh: fresh,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            const newCurrentVolume = {
                ...currentVolume,
                wallets: data.volume.wallets,
            };
            updateVolume(newCurrentVolume);
            setCurrentVolume(newCurrentVolume);
            toast.success("New wallets has been generated successfully");
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to generate new wallets!");
        }
        setOpenLoading(false);
    };

    const handleDownloadWallets = async () => {
        if (!currentVolume._id) {
            return;
        }

        setLoadingPrompt("Downloading wallets...");
        setOpenLoading(true);
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/volume/download-wallets`,
                {
                    volumeId: currentVolume._id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );

            const downloadFile = (data, fileName) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            };

            downloadFile(data, `wallets_volume.csv`);
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to download wallets!");
        }
        setOpenLoading(false);
    };

    const handleOKMinMaxTokenAmounts = async (minAmount, maxAmount) => {

        console.log("walletChecked : ", walletChecked);

        function getRandomNumber(min, max) {

            const ret = (Math.random() * (max - min)) + min;
            return ret.toFixed(5);
        }

        try {
            let minX = -1;
            let maxX = -1;
            if (minAmount.charAt(minAmount.length - 1) === '%') {
                minX = Number(minAmount.slice(0, minAmount.length - 1));
                minX = Number(tokenInfo.totalSupply) * minX / 100;
            }
            else
                minX = Number(minAmount);

            if (isNaN(minX) || minX <= 0) {
                toast.warn("Invalid minimum amount");
                return;
            }

            if (maxAmount.charAt(maxAmount.length - 1) === '%') {
                maxX = Number(maxAmount.slice(0, maxAmount.length - 1));
                maxX = Number(tokenInfo.totalSupply) * maxX / 100;
            }
            else
                maxX = Number(maxAmount);

            if (isNaN(maxX) || maxX <= 0) {
                toast.warn("Invalid maximum amount");
                return;
            }

            if (minX > maxX) {
                const t = minX;
                minX = maxX;
                maxX = t;
            }

            console.log("Min:", minX, "Max:", maxX);

            let newWalletTokenAmount = [...walletTokenAmount];
            for (let i = 0; i < newWalletTokenAmount.length; i++) {
                if (walletChecked[i])
                    newWalletTokenAmount[i] = getRandomNumber(minX, maxX);
            }

            console.log("TOKEN AMOUNT:", newWalletTokenAmount);

            setWalletTokenAmount(newWalletTokenAmount);
        }
        catch (err) {
            console.log(err);
            toast.warn("Invalid minimum/maximum amount");
        }

        setTokenAmountDialog(false);
    };

    const handleSetTokenAmounts = () => {
        const selectedWallets = walletChecked.filter((item) => item === true);
        if (selectedWallets.length === 0) {
            toast.warn("Please select wallets to set token amount");
            return;
        }
        setTokenAmountDialog(true);
    };


    const handleOKMinMaxEthAmounts = async (minAmount, maxAmount) => {

        console.log("walletChecked : ", walletChecked);

        function getRandomNumber(min, max) {
            const ret = (Math.random() * (max - min)) + min;
            console.log("min : ", min, " max : ", max, " ret : ", ret);
            return ret.toFixed(3);
        }

        try {
            let minX = -1;
            let maxX = -1;
            if (minAmount.charAt(minAmount.length - 1) === '%') {
                minX = Number(minAmount.slice(0, minAmount.length - 1));
                minX = Number(tokenInfo.totalSupply) * minX / 100;
            }
            else
                minX = Number(minAmount);

            if (isNaN(minX) || minX <= 0) {
                toast.warn("Invalid minimum amount");
                return;
            }

            if (maxAmount.charAt(maxAmount.length - 1) === '%') {
                maxX = Number(maxAmount.slice(0, maxAmount.length - 1));
                maxX = Number(tokenInfo.totalSupply) * maxX / 100;
            }
            else
                maxX = Number(maxAmount);

            if (isNaN(maxX) || maxX <= 0) {
                toast.warn("Invalid maximum amount");
                return;
            }

            if (minX > maxX) {
                const t = minX;
                minX = maxX;
                maxX = t;
            }

            let newWalletEthAmount = [...walletEthAmount];
            for (let i = 0; i < newWalletEthAmount.length; i++) {
                if (walletChecked[i])
                    newWalletEthAmount[i] = getRandomNumber(minX, maxX);
            }
            setWalletEthAmount(newWalletEthAmount);
            setEthAmountDialog(false);
            updateEthToDisperse();
        }
        catch (err) {
            console.log(err);
            toast.warn("Invalid minimum/maximum amount");
        }

        setTokenAmountDialog(false);
    };

    const handleSetETHAmounts = () => {
        const selectedWallets = walletChecked.filter((item) => item === true);
        if (selectedWallets.length === 0) {
            toast.warn("Please select wallets to set additional ETH amount");
            return;
        }
        setEthAmountDialog(true);
    };

    const handleDisperseETH = async () => {

        try {
            setLoadingPrompt("Dispersing Tokens...");
            setOpenLoading(true);

            const buyItems = [];
            currentVolume.wallets.map((item, index) => {
                if (walletChecked[index]) {
                    buyItems.push({
                        address: item.address,
                        initialEthAmount: walletEthAmount[index]
                    });
                }
            });

            if (buyItems.length > 0) {
                setRunningStatus("disperse");
                await axios.post(`${SERVER_URL}/api/v1/volume/disperse`,
                    {
                        chainId,
                        volumeId: currentVolume._id,
                        wallets: buyItems
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "MW-USER-ID": localStorage.getItem("access-token"),
                        },
                    }
                );
            } else {
                toast.warn("Please select the wallets.");
            }

        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
        }
    }

    const handleStartBot = async () => {

        try {
            setLoadingPrompt("Buying Tokens...");
            setOpenLoading(true);

            const buyItems = [];
            currentVolume.wallets.map((item, index) => {
                if (walletChecked[index]) {
                    buyItems.push({
                        address: item.address,
                        initialEthAmount: walletEthAmount[index]
                    });
                }
            });


            await axios.post(`${SERVER_URL}/api/v1/volume/disperse`,
                {
                    chainId,
                    volumeId: currentVolume._id,
                    wallets: buyItems
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            setRunningStatus("ready");
            setOpenLoading(false);
        }
    }

    const handleCollectAllEth = async () => {
        if (!currentVolume._id)
            return;

        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (!isValidAddress(targetWallet)) {
            toast.warn("Please input wallet to send ETH!");
            return;
        }

        const validWalletChecked = walletChecked.filter(item => item === true);

        setLoadingPrompt("Collecting all ETH...");
        setOpenLoading(true);
        try {
            let wallets = [];
            for (let i = 0; i < currentVolume.wallets.length; i++) {
                if (walletChecked[i]) {
                    wallets = [
                        ...wallets,
                        currentVolume.wallets[i].address,
                    ];
                }
            }

            setRunningStatus("collecting");
            await axios.post(`${SERVER_URL}/api/v1/volume/collect-all-eth`,
                {
                    volumeId: currentVolume._id,
                    chainId,
                    targetWallet,
                    wallets
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to collect all ETH!");
            setOpenLoading(false);
            setRunningStatus("ready");
        }
    };

    const updateEthToDisperse = (checkWallets = walletChecked) => {
        let sum = 0;
        for (let j = 0; j < walletEthAmount.length; j++) {
            if (checkWallets[j])
                sum += Number(walletEthAmount[j]);
        }
        setEthToDisperse(sum.toFixed(5));
    }

    const handleWalletAllChecked = (e) => {
        const newWalletAllChecked = !walletAllChecked;
        setWalletAllChecked(newWalletAllChecked);
        setWalletChecked(walletChecked.map(() => newWalletAllChecked));

        updateEthToDisperse(walletChecked.map(() => newWalletAllChecked));
    };

    const handleWalletChanged = (index, key, value) => {
        console.log("Wallet changed:", index, key, value);
        if (key === "checked") {
            let newWalletChecked = [...walletChecked];
            newWalletChecked[index] = !newWalletChecked[index];
            setWalletChecked(newWalletChecked);

            let newWalletAllChecked = true;
            for (let i = 0; i < newWalletChecked.length; i++)
                newWalletAllChecked &&= newWalletChecked[i];
            setWalletAllChecked(newWalletAllChecked);

            let sum = 0;
            for (let j = 0; j < newWalletChecked.length; j++) {
                if (newWalletChecked[j]) {
                    sum += Number(walletEthAmount[j]);
                }
            }
            setEthToDisperse(sum.toFixed(5));
        }
        else if (key === "token_amount") {
            let newWalletTokenAmount = [...walletTokenAmount];
            newWalletTokenAmount[index] = value;
            setWalletTokenAmount(newWalletTokenAmount);
        }
        else if (key === "eth_amount") {
            let newWalletETHAmount = [...walletEthAmount];
            newWalletETHAmount[index] = value;
            setWalletEthAmount(newWalletETHAmount);
        }
    };

    const handleBuyTokens = async () => {
        if (!currentVolume._id)
            return;

        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (!isValidAddress(token)) {
            toast.warn("Invalid token address!");
            return;
        }

        if (!isValidAddress(zombieWallet.address)) {
            toast.warn("Invalid zombie wallet");
            return;
        }

        const validWalletChecked = walletChecked.filter(item => item === true);
        if (validWalletChecked.length === 0) {
            toast.warn("Please check wallets to buy tokens");
            return;
        }

        try {
            setLoadingPrompt("Buying Tokens...");
            setOpenLoading(true);

            const buyItems = [];
            currentVolume.wallets.map((item, index) => {
                if (walletChecked[index]) {
                    buyItems.push({
                        address: item.address,
                        initialEthAmount: walletEthBalance[index]
                    });
                }
            });

            setRunningStatus("buying");
            axios.post(`${SERVER_URL}/api/v1/volume/buy`,
                {
                    volumeId: currentVolume._id,
                    chainId: chainId,
                    token: token,
                    wallets: buyItems,
                    zombie: zombieWallet,
                    gasPriceMultiplier: gasPriceMultiplier === "" ? 0 : Math.round(Number(gasPriceMultiplier)),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
            setRunningStatus("ready");
        }
    };

    const handleSellTokens = async () => {
        if (!currentVolume._id)
            return;

        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (!isValidAddress(token)) {
            toast.warn("Invalid token address!");
            return;
        }

        if (!isValidAddress(zombieWallet.address)) {
            toast.warn("Invalid zombie wallet");
            return;
        }

        const validWalletChecked = walletChecked.filter(item => item === true);
        if (validWalletChecked.length === 0) {
            toast.warn("Please check wallets to buy tokens");
            return;
        }

        try {
            setLoadingPrompt("Buying Tokens...");
            setOpenLoading(true);

            const buyItems = [];
            currentVolume.wallets.map((item, index) => {
                if (walletChecked[index]) {
                    buyItems.push({
                        address: item.address,
                        initialEthAmount: walletEthBalance[index]
                    });
                }
            });

            setRunningStatus("selling");
            await axios.post(`${SERVER_URL}/api/v1/volume/sell`,
                {
                    volumeId: currentVolume._id,
                    chainId: chainId,
                    token: token,
                    wallets: buyItems,
                    zombie: zombieWallet,
                    gasPriceMultiplier: gasPriceMultiplier === "" ? 0 : Math.round(Number(gasPriceMultiplier)),
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
            setRunningStatus("ready");
        }
    };

    return (
        <div className="flex flex-row">
            <MySidebar1 />
            <div className={`${className} w-full flex flex-col text-white rounded-[4px] border border-gray-highlight p-4 pb-3 m-4`}>
                <ZombieDialog isOpen={zombieDialog} index={zombieIndex} onOK={handleOKZombiePrivateKey} onCancel={() => setZombieDialog(false)} />
                <NewWalletDialog isOpen={newWalletDialog} onOK={handleOKNewWallet} onCancel={() => setNewWalletDialog(false)} />

                <TokenAmountDialog isOpen={tokenAmountDialog} onOK={handleOKMinMaxTokenAmounts} onCancel={() => setTokenAmountDialog(false)} />
                <EthAmountDialog isOpen={ethAmountDialog} onOK={handleOKMinMaxEthAmounts} onCancel={() => setEthAmountDialog(false)} />

                <div className="flex flex-col">
                    <div className="flex items-start justify-between w-full h-auto">
                        <div className="flex items-center font-sans text-xs font-medium text-white">
                            <div className="font-bold uppercase text-3xl">
                                Volume & Holder Bot </div>
                            {currentVolume._id &&
                                <div className="pl-1 font-bold uppercase text-green-normal">{currentVolume.name ? `${currentVolume.name}` : "No project"}</div>
                            }
                            {currentVolume?.token?.address &&
                                <>
                                    <div className="mx-2 text-gray-normal opacity-30">/</div>
                                    <div className="font-semibold text-gray-normal">{ellipsisAddress(currentVolume?.token?.address)}</div>
                                    {copied["token_address"] ?
                                        (<svg xmlns="http://www.w3.org/2000/svg" className="w-3.5 h-3.5 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                        </svg>) :
                                        <FaRegCopy className="w-3.5 h-3.5 ml-2 transition ease-in-out transform cursor-pointer active:scale-95 duration-100" onClick={() => copyToClipboard("token_address", currentVolume?.token?.address)} />}
                                    <a href={`https://${(currentVolume?.token?.chainId === 8453) ? "basescan.org" : "etherscan.io"}/address/${currentVolume?.token?.address}`} target="_blank" rel="noreferrer">
                                        <img className="w-3.5 h-3.5 object-contain ml-2" src="/assets/etherscan.png" alt="etherscan" />
                                    </a>
                                    <a href={`https://www.dextools.io/app/en/${(currentVolume?.token?.chainId === 8453) ? "base" : "ether"}/pair-explorer/${currentVolume?.token?.address}`} target="_blank" rel="noreferrer">
                                        <img className="w-3.5 h-3.5 object-contain ml-2" src="/assets/dextool.png" alt="dextools" />
                                    </a>
                                    <a href={`https://dexscreener.com/${(currentVolume?.token?.chainId === 8453) ? "base" : "ethereum"}/${currentVolume?.token?.address}`} target="_blank" rel="noreferrer">
                                        <img className="w-3.5 h-3.5 object-contain ml-2" src="/assets/dexscreener.png" alt="dexscreener" />
                                    </a>
                                </>
                            }
                        </div>
                        <div className="flex">
                            <button
                                className={`rounded-sm cursor-pointer w-9 h-9 bg-green-normal ${disabled ? "!bg-gray-highlight text-gray-normal" : "active:scale-95 transition duration-100 ease-in-out transform "}`}
                                disabled={disabled}
                                onClick={() => {
                                    handleSaveVolume(zombieWallet);
                                }}>
                                <FaSave className="w-4 h-4 m-auto" />
                            </button>
                        </div>
                    </div>

                    <div className="w-full mt-[6px] grid grid-cols-12 gap-3">
                        <div className="col-span-8 md:col-span-4 2xl:col-span-3">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Token Address<span className="pl-1 text-green-normal">*</span>
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                placeholder="Enter Address"
                                disabled={disabled}
                                value={token}
                                onChange={(e) => setToken(e.target.value)}
                            />
                        </div>
                        <div className="col-span-8 md:col-span-4 2xl:col-span-3">
                            <Popover className="relative flex items-center font-sans text-xs uppercase text-gray-normal">
                                <div className="whitespace-nowrap">Zombie Wallet<span className="pl-1 text-green-normal">*</span></div>
                                <Popover.Button className="border border-red-normal text-[6px] flex items-center justify-center cursor-pointer rounded-full w-3 h-3 ml-1">
                                    <FaQuestion className="text-green-normal" />
                                </Popover.Button>
                                <Popover.Panel className="absolute z-10 px-2 py-1 text-xs text-center text-white normal-case border rounded-sm bg-gray-highlight bottom-5 border-red-normal">
                                    This wallet distributes ETH to all wallets.
                                </Popover.Panel>
                            </Popover>
                            <div className={`flex items-center justify-between outline-none border border-gray-border text-gray-normal font-sans text-sm pl-2.5 bg-transparent w-full h-button mt-1 pr-1 ${disabled && "text-gray-border border-gray-highlight"}`}>
                                <div className={`w-full pr-1 truncate ${zombieWallet.address && "text-white"}`}>
                                    {
                                        zombieWallet.address ?
                                            ellipsisAddress(zombieWallet.address) :
                                            "NOT SET"
                                    }
                                </div>
                                <div className="flex items-center text-base">
                                    {zombieWallet.address && !copied["zombie_wallet_0"] &&
                                        <FaRegCopy className="w-4 cursor-pointer text-gray-normal hover:text-green-normal" onClick={() => copyToClipboard("zombie_wallet_0", zombieWallet.address)} />
                                    }
                                    {zombieWallet.address && copied["zombie_wallet_0"] &&
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                        </svg>
                                    }
                                    {!disabled && <FaEllipsisV className="w-4 ml-1 cursor-pointer text-gray-normal hover:text-green-normal" onClick={() => handleSetZombieWallet()} />}
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-6 2xl:col-span-3">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Generating wallets
                            </div>

                            <div className={`flex items-center justify-between outline-none border border-gray-border text-gray-normal font-sans text-sm pl-2.5 bg-transparent w-full h-button mt-1 pr-1 ${disabled && "text-gray-border border-gray-highlight"}`}>
                                <button
                                    className="w-full h-button rounded-[4px] justify-left items-center gap-1 inline-flex active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap items-center "
                                    disabled={disabled}
                                    onClick={() => setNewWalletDialog(true)}
                                >
                                    <IoIosAddCircle className="text-lg text-green-normal mr-1" />
                                    Generate Wallets
                                </button>
                            </div>
                        </div>

                        <div className="col-span-12 md:col-span-6 2xl:col-span-3">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Download Private Key
                            </div>
                            <div className={`flex items-center justify-between outline-none border border-gray-border text-gray-normal font-sans text-sm pl-2.5 bg-transparent w-full h-button mt-1 pr-1 ${disabled && "text-gray-border border-gray-highlight"}`}>
                                <button
                                    className="pl-3 pr-4 h-button rounded-[4px] justify-left items-center gap-1 inline-flex active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap items-center"
                                    disabled={disabled}
                                    onClick={handleDownloadWallets}
                                >
                                    <IoIosDownload className="text-lg text-green-normal mr-1" />
                                    Download Wallets
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between w-full gap-2 mt-3 mb-3 font-sans">
                        <div className="flex items-center gap-3 font-sans text-sm text-gray-normal">
                            <div>
                                Selected: <span className="text-white">{walletChecked.filter(wal => wal).length}</span>
                            </div>
                            {/* <div>
                                Token balance: <span className="text-white">{getSelectedTokenBalance()}</span>
                            </div> */}
                            <div>
                                ETH to Disperse: <span className="text-white">{ethToDisperse}</span>
                            </div>
                            {/* <div>
                                Tokens to buy: <span className="text-white">{getSelectedTokensToBuy()}</span>
                            </div> */}
                        </div>
                        <div className="flex flex-col justify-end gap-2 lg:items-center lg:flex-row">
                            {/* <button
                                className="pl-3 pr-4 h-button rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-100 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                                disabled={disabled}
                                onClick={handleSetTokenAmounts}
                            >
                                <FaDatabase className="text-sm text-green-normal" />
                                Set Token amount
                            </button> */}
                            <button
                                className="pl-3 pr-4 h-button rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-100 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                                disabled={disabled}
                                onClick={handleSetETHAmounts}
                            >
                                <FaEthereum className="text-sm text-green-normal" />
                                Set ETH amount
                            </button>
                        </div>
                    </div>
                    <div className="w-full overflow-visible font-sans">
                        <div className="flex flex-col w-full h-full text-white bg-transparent bg-clip-border">
                            <div className="relative border border-gray-highlight">
                                <div className="h-[calc(100vh-435px)] 2xl:h-[calc(100vh-365px)] overflow-y-auto">
                                    {(!currentVolume.wallets || currentVolume.wallets.length === 0) &&
                                        <div className="absolute flex items-center justify-center gap-2 my-3 text-base font-bold text-center uppercase -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-gray-border">
                                            <FaExclamationTriangle className="text-sm opacity-50 text-green-normal" /> No Wallet
                                        </div>
                                    }
                                    <table className="min-w-[700px] w-full text-xs">
                                        <thead className=" text-gray-normal">
                                            <tr className="uppercase h-7 bg-[#262626] sticky top-0 z-10">
                                                <th className="w-8 text-center">
                                                    <div className="flex items-center justify-center">
                                                        <input type="checkbox"
                                                            className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                                                            checked={walletAllChecked}
                                                            onChange={handleWalletAllChecked} />
                                                    </div>
                                                </th>
                                                <th className="w-8">
                                                    <p className="leading-none text-center">
                                                        #
                                                    </p>
                                                </th>
                                                <th className="text-center">
                                                    <p className="leading-none text-center">
                                                        Address
                                                    </p>
                                                </th>
                                                <th className="w-[20%] text-center">
                                                    <p className="leading-none text-left">
                                                        ETH Balance
                                                    </p>
                                                </th>
                                                <th className="w-[20%] text-center">
                                                    <p className="leading-none text-left">
                                                        Token Balance
                                                    </p>
                                                </th>

                                                <th className="w-[15%]">
                                                    <p className="leading-none text-center">
                                                        Disperse ETH
                                                    </p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-xs text-white" onMouseLeave={handleMouseUp}>
                                            {
                                                currentVolume.wallets &&
                                                currentVolume.wallets.map((item, index) => {
                                                    return (
                                                        <tr key={index}
                                                            className={`${index % 2 === 1 && "bg-[#ffffff02]"} hover:bg-[#ffffff08] ${walletChecked[index] && "!bg-[#00000030]"} h-8`}
                                                        >
                                                            <td className="text-center"
                                                                onMouseDown={(e) => handleMouseDown(e, index)}
                                                                onMouseEnter={() => handleMouseEnter(index)}
                                                                onMouseUp={handleMouseUp}
                                                            >
                                                                <div className="flex items-center justify-center">
                                                                    <input type="checkbox"
                                                                        className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                                                                        checked={walletChecked[index]}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="">
                                                                <p className="leading-none text-center text-gray-normal">
                                                                    {index + 1}
                                                                </p>
                                                            </td>
                                                            <td className="">
                                                                <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal text-gray-normal">
                                                                    <p className="bg-transparent border-none outline-none">
                                                                        {/* {item.address} */}
                                                                        {ellipsisAddress(item.address, 12)}
                                                                    </p>
                                                                    {
                                                                        copied["wallet_" + index] ?
                                                                            (<svg xmlns="http://www.w3.org/2000/svg" className="w-3 h-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                                                                            </svg>) :
                                                                            (<FaRegCopy className="w-3 h-3 transition duration-100 ease-in-out transform cursor-pointer active:scale-95" onClick={() => copyToClipboard("wallet_" + index, item.address)} />)
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="">
                                                                <p className="flex items-center justify-start text-yellow-normal">
                                                                    <FaEthereum className="mr-1 opacity-50 text-gray-normal" />
                                                                    {walletEthBalance[index]}
                                                                </p>
                                                            </td>
                                                            <td className="">
                                                                <p className="flex items-center justify-start text-white">
                                                                    <FaDatabase className="mr-1 opacity-50 text-xxs text-gray-normal" />
                                                                    <span>{Number(walletTokenBalance[index]?.split(".")[0] ?? "0").toLocaleString()}</span>
                                                                    <span className="font-normal text-gray-normal">.{walletTokenBalance[index]?.split(".")[1] ?? "00"}</span>
                                                                </p>
                                                            </td>
                                                            {/* <td className="text-center">
                                                                <input
                                                                    className="outline-none border border-gray-highlight font-medium text-gray-normal placeholder:text-gray-border text-xs px-2.5 bg-transparent text-center w-[150px] h-[26px]"
                                                                    disabled={disabled}
                                                                    value={walletTokenAmount[index]}
                                                                    onChange={(e) => handleWalletChanged(index, "token_amount", e.target.value)} />
                                                            </td> */}
                                                            <td className="text-center">
                                                                <input
                                                                    className="outline-none border border-gray-highlight font-medium text-gray-normal placeholder:text-gray-border text-xs px-2.5 bg-transparent text-center w-[100px] h-[26px]"
                                                                    disabled={disabled}
                                                                    value={walletEthAmount[index]}
                                                                    onChange={(e) => handleWalletChanged(index, "eth_amount", e.target.value)} />
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="relative flex items-center justify-between h-full gap-3 mt-3 text-white bg-transparent bg-clip-border">
                        <div className="flex items-center grow">
                            <div className="flex items-center font-sans text-gray-normal">
                                <FaGasPump className="text-sm" />
                                <span className="pl-2 font-medium text-white">{gasPrice}</span>
                                <IoIosClose className="p-1 text-3xl text-gray-normal" />
                                <input
                                    className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-1.5 bg-transparent w-14 h-[24px]"
                                    placeholder="125%"
                                    value={gasPriceMultiplier}
                                    onChange={(e) => setGasPriceMultiplier(e.target.value)}
                                />
                                <button className="flex items-center justify-center w-6 h-6 ml-1 transition duration-100 ease-in-out transform rounded-full bg-gray-highlight active:scale-90" onClick={handleQueryGasPrice}>
                                    <IoIosRefresh className="text-xs font-bold cursor-pointer text-gray-normal" />
                                </button>
                            </div>
                            <div className="ml-3 font-sans text-xs uppercase text-gray-normal whitespace-nowrap">
                                Target Wallet:
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button ml-2 grow max-w-[430px]"
                                placeholder="Target Wallet Address"
                                value={targetWallet}
                                onChange={(e) => setTargetWallet(e.target.value)}
                            />
                        </div>
                        <div className="flex items-center gap-3">
                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-green-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none"
                                onClick={handleCollectAllEth}
                            >
                                Collect All ETH
                            </button>
                            <div className="w-[1px] h-6 border-r border-gray-normal opacity-40 mx-1"></div>

                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-green-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
                                disabled={disabled}
                                onClick={handleDisperseETH}
                            >
                                DISPERSE ETH
                            </button>
                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-green-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
                                disabled={disabled}
                                onClick={handleBuyTokens}
                            >
                                BUY
                            </button>
                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-green-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
                                disabled={disabled}
                                onClick={handleSellTokens}
                            >
                                SELL
                            </button>

                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 bg-red-700 active:scale-95 transition duration-100 ease-in-out transform focus:outline-none disabled:opacity-50 disabled:transform-none disabled:cursor-not-allowed"
                                // disabled={disabled}
                                style={{ zIndex: 4000 }}
                                disabled = {runningStatus == "ready"}
                                onClick={() => {
                                    setOpenLoading(false);
                                }}
                            >
                                STOP BOT
                            </button>
                        </div>
                    </div>
                </div>
            </div >
            <MySidebar2 />
        </div>
    );
}
