import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Listbox } from "@headlessui/react";
import { IoIosArrowDown } from "react-icons/io";
import { useAccount, useChainId } from "wagmi";
import { ethers } from "ethers";
import axios from "axios";

import { AppContext } from "../App";
import NotifyAddressDialog from "../components/Dialogs/NotifyAddressDialog";
import { useEthersSigner } from "../utils/provider";
import { isValidAddress, sleep } from "../utils/methods";
import { TEMPLATES } from "../utils/constants";

import MySidebar1 from "./MySidebar1";
import MySidebar2 from "./MySidebar2";

export default function DeployPage({ className }) {
    const {
        SERVER_URL,
        setLoadingPrompt,
        setOpenLoading,
    } = useContext(AppContext);
    const chainId = useChainId();
    const { isConnected } = useAccount();
    const signer = useEthersSigner(chainId);

    const [template, setTemplate] = useState(TEMPLATES[0]);
    const [name, setName] = useState("");
    const [symbol, setSymbol] = useState("");
    const [decimals, setDecimals] = useState("");
    const [totalSupply, setTotalSupply] = useState("");
    const [maxTokensPerTxn, setMaxTokensPerTxn] = useState("");
    const [maxTokensPerWallet, setMaxTokensPerWallet] = useState("");
    const [maxSwapbackThreshold, setMaxSwapbackThreshold] = useState("");
    const [marketingOption, setMarketingOption] = useState(true);
    const [marketingBuyTax, setMarketingBuyTax] = useState("");
    const [marketingSellTax, setMarketingSellTax] = useState("");
    const [marketingWallet, setMarketingWallet] = useState("");
    const [developmentOption, setDevelopmentOption] = useState(true);
    const [developmentBuyTax, setDevelopmentBuyTax] = useState("");
    const [developmentSellTax, setDevelopmentSellTax] = useState("");
    const [developmentWallet, setDevelopmentWallet] = useState("");

    const [taxWallet, setTaxWallet] = useState("");
    const [initialBuyTax, setInitialBuyTax] = useState("");
    const [initialSellTax, setInitialSellTax] = useState("");
    const [buyTax, setBuyTax] = useState("");
    const [sellTax, setSellTax] = useState("");
    const [reduceBuyTaxAt, setReduceBuyTaxAt] = useState("");
    const [reduceSellTaxAt, setReduceSellTaxAt] = useState("");

    const [notifyAddressDialog, setNotifyAddressDialog] = useState(false);
    const [contractAddress, setContractAddress] = useState("");

    const isTemplate56 = template === TEMPLATES[4] || template === TEMPLATES[5];

    const handleDeployToken = async () => {

        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (name === "") {
            toast.warn("Please input token name");
            return;
        }

        if (symbol === "") {
            toast.warn("Please input token symbol");
            return;
        }

        const numDecimals = parseInt(decimals);
        if (isNaN(numDecimals) || numDecimals <= 0) {
            toast.warn("Invalid decimals");
            return;
        }

        const numTotalSupply = parseInt(totalSupply.replaceAll(",", ""));
        if (isNaN(numTotalSupply) || numTotalSupply <= 0) {
            toast.warn("Invalid total supply");
            return;
        }

        const numMaxTokensPerTxn = parseInt(maxTokensPerTxn.replaceAll(",", ""));
        if (isNaN(numMaxTokensPerTxn) || numMaxTokensPerTxn <= 0 || numMaxTokensPerTxn > numTotalSupply) {
            toast.warn("Invalid max tokens per transaction");
            return;
        }

        const numMaxTokensPerWallet = parseInt(maxTokensPerWallet.replaceAll(",", ""));
        if (isNaN(numMaxTokensPerWallet) || numMaxTokensPerWallet <= 0 || numMaxTokensPerWallet > numTotalSupply) {
            toast.warn("Invalid max tokens per wallet");
            return;
        }

        const numMaxSwapbackThreshold = parseInt(maxSwapbackThreshold.replaceAll(",", ""));
        if (isNaN(numMaxSwapbackThreshold) || numMaxSwapbackThreshold <= 0 || numMaxSwapbackThreshold > numTotalSupply) {
            toast.warn("Invalid max swapback threshold");
            return;
        }

        if (!isTemplate56) {
            if (marketingOption) {
                if (!isValidAddress(marketingWallet)) {
                    toast.warn("Invalid marketing wallet");
                    return;
                }

                const numBuyTax = parseInt(marketingBuyTax);
                if (isNaN(numBuyTax) || numBuyTax < 0) {
                    toast.warn("Invalid marketing buy tax");
                    return;
                }

                const numSellTax = parseInt(marketingSellTax);
                if (isNaN(numSellTax) || numSellTax < 0) {
                    toast.warn("Invalid marketing sell tax");
                    return;
                }
            }

            if (developmentOption) {
                if (!isValidAddress(developmentWallet)) {
                    toast.warn("Invalid development wallet");
                    return;
                }

                const numBuyTax = parseInt(developmentBuyTax);
                if (isNaN(numBuyTax) || numBuyTax < 0) {
                    toast.warn("Invalid development buy tax");
                    return;
                }

                const numSellTax = parseInt(developmentSellTax);
                if (isNaN(numSellTax) || numSellTax < 0) {
                    toast.warn("Invalid development sell tax");
                    return;
                }
            }
        }
        else {
            if (!isValidAddress(taxWallet)) {
                toast.warn("Invalid tax wallet");
                return;
            }

            const numInitialBuyTax = parseInt(initialBuyTax);
            if (isNaN(numInitialBuyTax) || numInitialBuyTax < 0) {
                toast.warn("Invalid initial buy tax");
                return;
            }

            const numInitialSellTax = parseInt(initialSellTax);
            if (isNaN(numInitialSellTax) || numInitialSellTax < 0) {
                toast.warn("Invalid initial sell tax");
                return;
            }

            const numBuyTax = parseInt(buyTax);
            if (isNaN(numBuyTax) || numBuyTax < 0) {
                toast.warn("Invalid buy tax");
                return;
            }

            const numSellTax = parseInt(sellTax);
            if (isNaN(numSellTax) || numSellTax < 0) {
                toast.warn("Invalid sell tax");
                return;
            }

            const numReduceBuyTaxAt = parseInt(reduceBuyTaxAt);
            if (isNaN(numReduceBuyTaxAt) || numReduceBuyTaxAt < 0) {
                toast.warn("Invalid reduce buy tax at");
                return;
            }

            const numReduceSellTaxAt = parseInt(reduceSellTaxAt);
            if (isNaN(numReduceSellTaxAt) || numReduceSellTaxAt < 0) {
                toast.warn("Invalid reduce sell tax at");
                return;
            }
        }

        setLoadingPrompt("Deploying token...");
        setOpenLoading(true);
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/misc/compile-token`,
                {
                    template,
                    name,
                    symbol,
                    decimals,
                    totalSupply: totalSupply.replaceAll(",", ""),
                    maxTokensPerTxn: maxTokensPerTxn.replaceAll(",", ""),
                    maxTokensPerWallet: maxTokensPerWallet.replaceAll(",", ""),
                    maxSwapbackThreshold: maxSwapbackThreshold.replaceAll(",", ""),
                    marketingOption,
                    marketingWallet,
                    marketingBuyTax,
                    marketingSellTax,
                    developmentOption,
                    developmentWallet,
                    developmentBuyTax,
                    developmentSellTax,
                    taxWallet,
                    initialBuyTax,
                    initialSellTax,
                    buyTax,
                    sellTax,
                    reduceBuyTaxAt,
                    reduceSellTaxAt,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            // console.log("Contract:", data.contract);
            const factory = new ethers.ContractFactory(data.contract.abi, data.contract.bytecode, signer);
            const contract = await factory.deploy();
            if (contract.target) {
                setLoadingPrompt("Verifying token...");

                const form = new FormData();
                form.append("apikey", process.env.REACT_APP_ETHERSCAN_API_KEY);
                form.append("module", "contract");
                form.append("action", "verifysourcecode");
                form.append("chainId", chainId);
                form.append("codeformat", data.contract.codeFormat);
                form.append("compilerversion", data.contract.compilerVersion);
                form.append("contractaddress", contract.target);
                form.append("contractname", data.contract.name);
                form.append("optimizationUsed", 0);
                form.append("sourceCode", JSON.stringify(data.contract.sourceCode));

                const sentTime = Date.now();
                while (Date.now() - sentTime <= 300000) {
                    try {
                        console.log("Sending verify request...");
                        const { data: verifyResponse } = await axios.post("https://api.etherscan.io/api", form, {
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                        });
                        await sleep(5000);

                        if (verifyResponse.message === "OK") {
                            console.log("Checking verify status...");
                            const { data: checkResponse } = await axios.get(`https://api.etherscan.io/api?module=contract&action=checkverifystatus&guid=${verifyResponse.result}&apikey=${process.env.REACT_APP_ETHERSCAN_API_KEY}`);

                            console.log("Check Status:", checkResponse);
                            if (checkResponse.message === "OK" || checkResponse.result === "Already Verified") {
                                console.log("Succeed to verify contract");
                                break;
                            }
                        }
                        else {
                            console.log("Failed to verify contract");
                            break;
                        }
                        await sleep(5000);
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
            }
            setContractAddress(contract.target);
            setNotifyAddressDialog(true);

            toast.success("Succeed to deploy token");
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to deploy token");
        }
        setOpenLoading(false);
    };

    return (
        <div className="flex flex-row">
            <MySidebar1 />
            <div className={`${className} w-full flex p-8 justify-center text-white font-sans`}>
                <NotifyAddressDialog isOpen={notifyAddressDialog} title="Token Contract" label="Contract Address" address={contractAddress} onClose={() => setNotifyAddressDialog(false)} />
                <div className="flex flex-col w-full ">
                    <div className="flex items-center justify-between w-full h-auto mb-5">
                        <div className="m-auto mt-10 text-xl font-medium text-white">
                            Deploy Token
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 w-full rounded-b-[10px]">
                        <div className="relative">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Template<span className="pl-1 text-green-normal">*</span>
                            </div>
                            <Listbox value={template} onChange={setTemplate}>
                                <Listbox.Button
                                    className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1 disabled:border-gray-highlight disabled:text-gray-border relative pr-7"
                                >
                                    <span className="flex items-center">
                                        <span className="block truncate">
                                            {template}
                                        </span>
                                    </span>
                                    <IoIosArrowDown className="absolute inset-y-0 -right-1 flex items-center w-8 pr-2 mt-2.5 pointer-events-none opacity-50" />
                                </Listbox.Button>
                                <Listbox.Options className="absolute z-20 w-full overflow-auto font-sans text-xs border border-t-0 text-gray-normal mt bg-gray-dark border-gray-border">
                                    {
                                        TEMPLATES.map((item, index) => {
                                            return (
                                                <Listbox.Option key={index}
                                                    className={`relative px-2 py-1 cursor-default hover:bg-gray-border ${item === template && "text-white"}`}
                                                    value={item}
                                                >
                                                    <div className="flex items-center">
                                                        <span className="block font-normal truncate">
                                                            {item}
                                                        </span>
                                                    </div>
                                                </Listbox.Option>
                                            );
                                        })
                                    }
                                </Listbox.Options>
                            </Listbox>
                        </div>
                        <div className="flex justify-between gap-4">
                            <div className="w-[50%]">
                                <div className="font-sans text-xs uppercase text-gray-normal">
                                    Name<span className="pl-1 text-green-normal">*</span>
                                </div>
                                <input
                                    className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                    placeholder="Enter token name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="w-[50%]">
                                <div className="font-sans text-xs uppercase text-gray-normal">
                                    Symbol<span className="pl-1 text-green-normal">*</span>
                                </div>
                                <input
                                    className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                    placeholder="Enter token symbol"
                                    value={symbol}
                                    onChange={(e) => setSymbol(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="flex justify-between gap-4">
                            <div className="w-[50%]">
                                <div className="font-sans text-xs uppercase text-gray-normal">
                                    Decimals<span className="pl-1 text-green-normal">*</span>
                                </div>
                                <input
                                    className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                    placeholder="Enter decimals"
                                    value={decimals}
                                    onChange={(e) => setDecimals(e.target.value)}
                                />
                            </div>
                            <div className="w-[50%]">
                                <div className="font-sans text-xs uppercase text-gray-normal">
                                    Total Supply<span className="pl-1 text-green-normal">*</span>
                                </div>
                                <input
                                    className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                    placeholder="Enter total supply"
                                    value={totalSupply}
                                    onChange={(e) => setTotalSupply(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Max Tokens Per Transaction<span className="pl-1 text-green-normal">*</span>
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                placeholder="Enter max tokens per transaction"
                                value={maxTokensPerTxn}
                                onChange={(e) => setMaxTokensPerTxn(e.target.value)}
                            />
                        </div>
                        <div className="">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Max Tokens Per Wallet<span className="pl-1 text-green-normal">*</span>
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                placeholder="Enter max tokens per wallet"
                                value={maxTokensPerWallet}
                                onChange={(e) => setMaxTokensPerWallet(e.target.value)}
                            />
                        </div>
                        <div className="">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Max Swapback Thereshold<span className="pl-1 text-green-normal">*</span>
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                placeholder="Enter max tokens per transaction"
                                value={maxSwapbackThreshold}
                                onChange={(e) => setMaxSwapbackThreshold(e.target.value)}
                            />
                        </div>
                        {
                            !isTemplate56 &&
                            (<div className="">
                                <label className="flex items-center cursor-pointer">
                                    <input type="checkbox" className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                                        checked={marketingOption}
                                    // onChange={(e) => setMarketingOption(e.target.checked)}
                                    />
                                    <span className="ml-3 text-xs text-gray-normal uppercase">Marketing</span>
                                </label>
                            </div>)
                        }
                        {
                            marketingOption && !isTemplate56 &&
                            (
                                <div className="">
                                    <div className="font-sans text-xs uppercase text-gray-normal">
                                        Marketing Wallet<span className="pl-1 text-green-normal">*</span>
                                    </div>
                                    <input
                                        className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                        placeholder="Enter marketing wallet"
                                        value={marketingWallet}
                                        onChange={(e) => setMarketingWallet(e.target.value)}
                                    />
                                </div>
                            )
                        }
                        {
                            marketingOption && !isTemplate56 &&
                            (
                                <div className="flex justify-between gap-4">
                                    <div className="w-[50%]">
                                        <div className="font-sans text-xs uppercase text-gray-normal">
                                            Buy Tax(%)<span className="pl-1 text-green-normal">*</span>
                                        </div>
                                        <input
                                            className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                            placeholder="Enter marketing buy tax"
                                            value={marketingBuyTax}
                                            onChange={(e) => setMarketingBuyTax(e.target.value)}
                                        />
                                    </div>
                                    <div className="w-[50%]">
                                        <div className="font-sans text-xs uppercase text-gray-normal">
                                            Sell Tax(%)<span className="pl-1 text-green-normal">*</span>
                                        </div>
                                        <input
                                            className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                            placeholder="Enter marketing sell tax"
                                            value={marketingSellTax}
                                            onChange={(e) => setMarketingSellTax(e.target.value)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {
                            !isTemplate56 &&
                            (
                                <div className="">
                                    <label className="flex items-center cursor-pointer">
                                        <input type="checkbox" className="w-4 h-4 outline-none bg-gray-highlight opacity-20 accent-green-normal ring-0"
                                            checked={developmentOption}
                                        // onChange={(e) => setDevelopmentOption(e.target.checked)}
                                        />
                                        <span className="ml-3 text-xs text-gray-normal uppercase">Development</span>
                                    </label>
                                </div>
                            )
                        }
                        {
                            developmentOption && !isTemplate56 &&
                            (
                                <div className="">
                                    <div className="font-sans text-xs uppercase text-gray-normal">
                                        Development Wallet<span className="pl-1 text-green-normal">*</span>
                                    </div>
                                    <input
                                        className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                        placeholder="Enter development wallet"
                                        value={developmentWallet}
                                        onChange={(e) => setDevelopmentWallet(e.target.value)}
                                    />
                                </div>
                            )
                        }
                        {
                            developmentOption && !isTemplate56 &&
                            (
                                <div className="flex justify-between gap-4">
                                    <div className="w-[50%]">
                                        <div className="font-sans text-xs uppercase text-gray-normal">
                                            Buy Tax(%)<span className="pl-1 text-green-normal">*</span>
                                        </div>
                                        <input
                                            className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                            placeholder="Enter development buy tax"
                                            value={developmentBuyTax}
                                            onChange={(e) => setDevelopmentBuyTax(e.target.value)}
                                        />
                                    </div>
                                    <div className="w-[50%]">
                                        <div className="font-sans text-xs uppercase text-gray-normal">
                                            Sell Tax(%)<span className="pl-1 text-green-normal">*</span>
                                        </div>
                                        <input
                                            className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                            placeholder="Enter development sell tax"
                                            value={developmentSellTax}
                                            onChange={(e) => setDevelopmentSellTax(e.target.value)}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        {
                            isTemplate56 &&
                            (<div className="">
                                <div className="font-sans text-xs uppercase text-gray-normal">
                                    Tax Wallet<span className="pl-1 text-green-normal">*</span>
                                </div>
                                <input
                                    className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                    placeholder="Enter tax wallet"
                                    value={taxWallet}
                                    onChange={(e) => setTaxWallet(e.target.value)}
                                />
                            </div>)
                        }
                        {
                            isTemplate56 &&
                            (<div className="flex justify-between gap-4">
                                <div className="w-[50%]">
                                    <div className="font-sans text-xs uppercase text-gray-normal">
                                        Initial Buy Tax(%)<span className="pl-1 text-green-normal">*</span>
                                    </div>
                                    <input
                                        className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                        placeholder="Enter initial buy tax"
                                        value={initialBuyTax}
                                        onChange={(e) => setInitialBuyTax(e.target.value)}
                                    />
                                </div>
                                <div className="w-[50%]">
                                    <div className="font-sans text-xs uppercase text-gray-normal">
                                        Initial Sell Tax(%)<span className="pl-1 text-green-normal">*</span>
                                    </div>
                                    <input
                                        className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                        placeholder="Enter initial sell tax"
                                        value={initialSellTax}
                                        onChange={(e) => setInitialSellTax(e.target.value)}
                                    />
                                </div>
                            </div>)
                        }
                        {
                            isTemplate56 &&
                            (<div className="flex justify-between gap-4">
                                <div className="w-[50%]">
                                    <div className="font-sans text-xs uppercase text-gray-normal">
                                        Buy Tax(%)<span className="pl-1 text-green-normal">*</span>
                                    </div>
                                    <input
                                        className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                        placeholder="Enter buy tax"
                                        value={buyTax}
                                        onChange={(e) => setBuyTax(e.target.value)}
                                    />
                                </div>
                                <div className="w-[50%]">
                                    <div className="font-sans text-xs uppercase text-gray-normal">
                                        Sell Tax(%)<span className="pl-1 text-green-normal">*</span>
                                    </div>
                                    <input
                                        className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                        placeholder="Enter sell tax"
                                        value={sellTax}
                                        onChange={(e) => setSellTax(e.target.value)}
                                    />
                                </div>
                            </div>)
                        }
                        {
                            isTemplate56 &&
                            (<div className="flex justify-between gap-4">
                                <div className="w-[50%]">
                                    <div className="font-sans text-xs uppercase text-gray-normal">
                                        Reduce Buy Tax At<span className="pl-1 text-green-normal">*</span>
                                    </div>
                                    <input
                                        className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                        placeholder="Enter reduce buy tax at"
                                        value={reduceBuyTaxAt}
                                        onChange={(e) => setReduceBuyTaxAt(e.target.value)}
                                    />
                                </div>
                                <div className="w-[50%]">
                                    <div className="font-sans text-xs uppercase text-gray-normal">
                                        Reduce Sell Tax At<span className="pl-1 text-green-normal">*</span>
                                    </div>
                                    <input
                                        className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                        placeholder="Enter reduce sell tax at"
                                        value={reduceSellTaxAt}
                                        onChange={(e) => setReduceSellTaxAt(e.target.value)}
                                    />
                                </div>
                            </div>)
                        }
                        <div className="relative flex h-full my-5 text-white bg-transparent justify-evenly bg-clip-border">
                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 inline-flex bg-green-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none"
                                onClick={handleDeployToken}
                            >
                                Deploy
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <MySidebar2 />
        </div>
    );
}