import { useContext, useEffect, useState } from "react";
import { generateAvatarURL } from "@cfx-kit/wallet-avatar";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

import { AppContext } from "../App";
import AvatarDropDown from "../components/AvatarDropdown";
import { useNavigate, useLocation } from "react-router-dom";

export default function NavBar({ className, breadCrumb }) {
    const { user, logout } = useContext(AppContext);
    const { address } = useAccount();
    const [randomAvatar, setRandomAvatar] = useState(generateAvatarURL(new Date().getTime().toString()));
    const navigate = useNavigate();

    const onViewProfile = () => {
        console.log("View Profile");
    };

    useEffect(() => {
        setRandomAvatar(generateAvatarURL(address?.toString() || new Date().getTime().toString()));
    }, [address]);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', padding: '50px', paddingBottom: '0px' }}>

            <img src="/assets/logo.png" className="w-24 h-12 logo_animate" onClick={() => {
                navigate("/dashboard");
            }} />

            <div style={{
                color: 'white',
                letterSpacing: '3px',
                fontSize: '28px',
                fontWeight: '600',
                width: '100%',
                marginLeft: '30px',
                display: 'flex',
                flexDirection: 'row'
            }}>
                {breadCrumb && breadCrumb.map((item, index) => {
                    return (
                        <>
                            <div className="bread-active" onClick={(event) => {
                                if (event.target.textContent == "Home") {
                                    navigate("/dashboard");
                                } else if (event.target.textContent == "Project") {
                                    navigate("/myprojects");
                                }else if (event.target.textContent == "Market Maker") {
                                    navigate("/market-maker");
                                }
                            }}>{item}</div>
                            {index < breadCrumb.length - 1 && (<>&nbsp;>&nbsp;</>)}
                        </>

                    );
                })}
            </div>

            <div className="flex items-center justify-between w-full gap-2 lg:justify-end lg:gap-5">

                <ConnectButton.Custom>
                    {
                        ({
                            account,
                            chain,
                            openAccountModal,
                            openChainModal,
                            openConnectModal,
                            authenticationStatus,
                            mounted,
                        }) => {
                            const ready = mounted && authenticationStatus !== 'loading';
                            const connected = ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');
                            if (!connected) {
                                return (
                                    <button className="px-6 h-10 rounded-[10px] hover:brightness-100 bg-[rgba(54,11,26,0.5)] border border-white text-white hover:brightness-75 hover:scale-110 transition duration-2000" onClick={openConnectModal}>
                                        Connect Wallet
                                    </button>
                                );
                            }

                            if (chain.unsupported) {
                                return (
                                    <button className="px-6 h-10 rounded-[10px] hover:brightness-100 bg-[rgba(54,11,26,0.5)] border border-white text-white hover:brightness-75 hover:scale-110 transition duration-2000" onClick={openChainModal}>
                                        Wrong Network
                                    </button>
                                );
                            }

                            return (
                                <div className="flex">
                                    <button
                                        className="px-2 h-8 lg:h-10 rounded-[4px] justify-center items-center gap-2.5 inline-flex bg-red-semi border border-green-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none"
                                        onClick={openChainModal}>
                                        <img src="/assets/eth_logo.png" alt="chain" />
                                        <div className="font-sans text-xs font-medium text-center text-white uppercase">
                                            {chain.name}
                                        </div>
                                    </button>
                                    <button
                                        className="px-2 h-8 lg:h-10 rounded-[4px] justify-center items-center gap-2.5 inline-flex bg-red-semi border border-yellow-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none ml-2"
                                        onClick={openAccountModal}>
                                        <div className="font-sans text-xs font-medium text-center text-white">
                                            {account.address.slice(0, 7) + "..." + account.address.slice(37)}
                                        </div>
                                    </button>
                                </div>
                            );
                        }
                    }
                </ConnectButton.Custom>
                <img src="/assets/common/logout.png" className="w-12 h-12 mr-2 brightness-50 hover:brightness-100 hover:cursor-pointer hover:scale-110 transition duration-2000"
                    alt="spinner" onClick={logout} />
                {/* <AvatarDropDown imageUrl={randomAvatar} name={user ? user.name : ""} address={address} onLogout={logout} onViewProfile={onViewProfile} /> */}

            </div>
        </div>
    );
}
