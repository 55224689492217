import Modal from "./Modal";

export default function RegisteredDialog({ isOpen, onOK }) {
    return (
        <Modal isOpen={isOpen}>
            {/* <div className="flex flex-col pt-5 font-sans">
                <div className="flex items-center justify-start w-full h-auto px-5 py-3 rounded-t-md bg-gray-highlight">
                    <div className="font-sans text-sm font-medium text-white uppercase">
                        Register
                    </div>
                </div>
                <div className="items-center w-full h-auto px-5 py-5 md:py-0 bg-gray-dark rounded-b-md">
                    <div className="text-gray-normal text-center text-base font-medium font-sans leading-[24.93px] mt-5">
                        Account has been registered successfully
                    </div>
                    <div className="flex items-center justify-center gap-5 my-5">
                        <button
                            className="pl-3 pr-4 h-[34px] grow rounded-[4px] justify-center items-center gap-1 bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                            onClick={onOK}>
                            OK
                        </button>
                    </div>
                </div>
            </div> */}
            <div className="flex flex-col font-sans border border-[rgba(255,255,255,1)] rounded-[6px]">
                <div className="flex flex-col items-center justify-start w-full h-auto px-5 py-3 rounded-t-md bg-white justify-center">
                    <img src="/assets/logo.png" className="w-24 h-12 m-2"></img>
                    <div className="font-sans text-xl font-medium text-black uppercase">
                        Register Dialog Alert
                    </div>
                </div>
                <div className="items-center w-full h-auto px-5 py-5 md:py-0 bg-gray-dark rounded-b-md">
                    <div className="text-center text-white font-medium font-sans leading-[24.93px] m-4 max-w-[500px]">
                        Your account has been successfully registered. <br/>
                        You are free to sign in.<br/>
                        Thank you.
                    </div>
                    <div className="flex items-center justify-center gap-5 my-5">
                        <button
                            className="w-full border border-[rgba(0,255,255,0.7)] pl-3 pr-4 h-[34px] grow rounded-[4px] justify-center items-center gap-1 bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                            onClick={onOK}>
                            OK
                        </button>
                        <button
                            className="w-full border border-[rgba(255,0,255,0.7)] pl-3 pr-4 h-[34px] grow rounded-[4px] justify-center items-center gap-1 bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                            onClick={onOK}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
