import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

import { AppContext } from "../App";
import RegisteredDialog from "../components/Dialogs/RegisteredDialog";
import { FaUserPlus } from "react-icons/fa";
import { IoIosLock } from "react-icons/io";

export default function SigninPage() {
    const { SERVER_URL, setLoadingPrompt, setOpenLoading, setUser } = useContext(AppContext);
    const [openRegistered, setOpenRegistered] = useState(false);

    const navigate = useNavigate();

    const [loginName, setLoginName] = useState("");
    const [loginPass, setLoginPass] = useState("");

    const [signupName, setSignupName] = useState("");
    const [signupPass, setSignupPass] = useState("");

    const handleSubmitSignIn = async (e) => {
        e.preventDefault();

        const name = loginName;
        const password = loginPass;
        if (name === "") {
            toast.warn("Please input user name");
            return;
        }

        if (password === "") {
            toast.warn("Please input password");
            return;
        }

        setLoadingPrompt("Logging in...");
        setOpenLoading(true);
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/user/login`,
                {
                    name,
                    password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (data.success) {
                localStorage.setItem("access-token", data.accessToken);
                setUser(data.user);
                // navigate("/dashboard");
            }
            else
                toast.warn("Failed to register");
        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
            toast.warn("Failed to login");
        }
    };

    const handleSubmitSignUp = async (e) => {
        e.preventDefault();

        const name = signupName;
        const password = signupPass;

        if (name === "") {
            toast.warn("Please input user name");
            return;
        }

        if (password.length < 8) {
            toast.warn("Password should be longer than 8 characters");
            return;
        }

        setLoadingPrompt("Registering...");
        setOpenLoading(true);
        try {
            const { data } = await axios.post(`${SERVER_URL}/api/v1/user/register`,
                {
                    name,
                    password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            if (data.success) {
                setOpenLoading(false);
                setOpenRegistered(true);
            }
            else
                toast.warn("Failed to register");
        }
        catch (err) {
            console.log(err);
            setOpenLoading(false);
            toast.warn("Failed to register");
        }
    };


    const switchers = [...document.querySelectorAll('.switcher')]

    switchers.forEach(item => {
        item.addEventListener('click', function () {
            switchers.forEach(item => item.parentElement.classList.remove('is-active'))
            this.parentElement.classList.add('is-active')
        })
    })

    return (
        <div className="flex flex-col">
            <RegisteredDialog isOpen={openRegistered}
                onOK={() => {
                    navigate("/login");
                    setOpenRegistered(false);
                }} />

            <section class="forms-section">
                <h1 class="section-title">ETHEREUM LAUNCHPAD</h1>
                <div class="forms">
                    <div class="form-wrapper is-active">
                        <button type="button" class="switcher switcher-login">
                            Sign in
                            <span class="underline"></span>
                        </button>
                        <form class="form form-login" onSubmit={handleSubmitSignIn}>
                            <fieldset>
                                <legend>Please, enter your email and password for login.</legend>
                                <div class="input-block">
                                    <label for="login-email">E-mail</label>
                                    <input id="login-email" type="email" value={loginName} onChange={(e) => { setLoginName(e.target.value) }} required />
                                </div>
                                <div class="input-block">
                                    <label for="login-password">Password</label>
                                    <input id="login-password" type="password" value={loginPass} onChange={(e) => { setLoginPass(e.target.value) }} required />
                                </div>
                            </fieldset>
                            <button type="submit" style={{ background: 'purple', color: 'white' }}>Sign in</button>
                        </form>
                    </div>
                    <div class="form-wrapper" onSubmit={handleSubmitSignUp}>
                        <button type="button" class="switcher switcher-signup">
                            Sign Up
                            <span class="underline"></span>
                        </button>
                        <form class="form form-signup">
                            <fieldset>
                                <legend>Please, enter your email, password and password confirmation for sign up.</legend>

                                <div class="input-block">
                                    <label for="signup-email">E-mail</label>
                                    <input id="signup-email" type="email" value={signupName} onChange={(e) => { setSignupName(e.target.value) }} required />
                                </div>
                                <div class="input-block">
                                    <label for="signup-password">Password</label>
                                    <input id="signup-password" value={signupPass} type="password" onChange={(e) => { setSignupPass(e.target.value) }} required />
                                </div>
                            </fieldset>
                            <button type="submit" style={{ background: 'lightslategrey', color: 'white' }}>Sign Up</button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
    );
}
