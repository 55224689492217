import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { IoIosAdd, IoIosAddCircle, IoIosRefresh } from "react-icons/io";
import { FaRegCopy, FaWallet, FaTrash, FaCheck, FaEye } from "react-icons/fa";
import axios from "axios";

import { AppContext } from "../App";
import AddExtraWalletDialog from "../components/Dialogs/AddExtraWalletDialog";
import AddEmailDialog from "../components/Dialogs/AddEmailDialog";
import NewProjectDialog from "../components/Dialogs/NewProjectDialog";
import ConfirmDialog from "../components/Dialogs/ConfirmDialog";

// import { getTokenListByOwner } from "../utils/solana";
import { ellipsisAddress, isValidAddress } from "../utils/methods";

export default function MyFooter({ className, img }) {
    const navigate = useNavigate();

    return (
        <div style={{ marginTop: '50px', marginBottom: '50px' }}>
            <hr style={{
                width: '85%',
                margin: 'auto',
                border: '1px solid',
                borderImage: 'linear-gradient(to right, transparent, white, transparent) 1'
            }}></hr>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '100px' }}>

                <div style={{ width: '20%', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img src="/assets/dashboard/Project.jpg"
                        className="btn_dashboard"
                        style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                            marginBottom: '20px',
                            borderRadius: '20px',
                            border: '3px solid gray',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            navigate("/myprojects");
                        }}
                    ></img>
                    <h1 style={{ color: 'white', fontSize: '24px', marginTop: '16px' }}>Project</h1>
                </div>
                <div style={{ width: '20%', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img src="/assets/dashboard/buy_sell.jpg"
                        className="btn_dashboard"
                        style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                            marginBottom: '20px',
                            borderRadius: '20px',
                            border: '3px solid gray',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            navigate("/sell");
                        }}
                    ></img>
                    <h1 style={{ color: 'white', fontSize: '24px', marginTop: '16px' }}>Buy & Sell</h1>
                </div>
                <div style={{ width: '20%', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img src="/assets/dashboard/MarketMaker.jpg"
                        className="btn_dashboard"
                        style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                            marginBottom: '20px',
                            borderRadius: '20px',
                            border: '3px solid gray',
                        }}
                        onClick={() => {
                            navigate("/market-maker");
                        }}
                    >
                    </img>

                    <h1 style={{ color: 'white', fontSize: '24px', marginTop: '16px' }}>Market Maker</h1>
                </div>
                <div style={{ width: '20%', margin: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img src="/assets/dashboard/ManageLP.jpg"
                        className="btn_dashboard"
                        style={{
                            objectFit: 'contain',
                            width: '100%',
                            height: '100%',
                            marginBottom: '20px',
                            borderRadius: '20px',
                            border: '3px solid gray',
                            cursor: 'pointer',

                        }}
                        onClick={() => {
                            navigate("/liquidity");
                        }}
                    ></img>
                    <h1 style={{ color: 'white', fontSize: '24px', marginTop: '16px' }}>Manage LP</h1>
                </div>
            </div>
        </div>
    );
}
