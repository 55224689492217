import Modal from "./Modal";
import React from 'react';

const LoadingDialog = ({ isOpen, prompt }) => {
    return (
        <Modal isOpen={isOpen} className="!z-[2000]">
            <div className="w-full h-auto p-4 bg-[rgba(255,255,255,0.2)] flex rounded-[50%] items-center">
                <img src="/assets/hourglass-svg.svg" className="w-32 h-32 rotating" alt="spinner" />
                {/* <div className="font-sans text-sm font-medium text-center text-white">
                    {prompt}
                </div> */}
            </div>
        </Modal>
    );
}

export default LoadingDialog;
