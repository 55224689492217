import { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { IoIosAdd, IoIosAddCircle, IoIosRefresh } from "react-icons/io";
import { FaRegCopy, FaWallet, FaTrash, FaCheck, FaEye } from "react-icons/fa";
import axios from "axios";

import { AppContext } from "../App";
import AddExtraWalletDialog from "../components/Dialogs/AddExtraWalletDialog";
import AddEmailDialog from "../components/Dialogs/AddEmailDialog";
import NewProjectDialog from "../components/Dialogs/NewProjectDialog";
import ConfirmDialog from "../components/Dialogs/ConfirmDialog";

// import { getTokenListByOwner } from "../utils/solana";
import { ellipsisAddress, isValidAddress } from "../utils/methods";


const data_sidebar = [
    { label: "Deploy Token", img: "/assets/dashboard/DeployToken.png", _link: "/deploy-token" },
    { label: "Manage LP", img: "/assets/dashboard/manageMoney.jpg", _link: "/liquidity" },
    { label: "Estimate", img: "/assets/dashboard/Estimate.png", _link: "/estimate" },
    { label: "Market Maker", img: "/assets/projects/marketmaker.jpg", _link : "/market-maker" }
];

export default function MySidebar2({ className, img , type}) {
    const navigate = useNavigate();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: type == 'onlyLeft' ? '100%' : '22.5%', marginTop : '16px' }}>
            {data_sidebar.map((item) => {
                return <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={item.img}
                        className="btn_dashboard"
                        style={{
                            // objectFit: 'contain',
                            width: '100%',
                            height: '150px',
                            borderRadius: '20px',
                            border: '3px solid gray',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            navigate(item._link);
                        }}
                    ></img>
                    <h1 style={{ color: 'white', fontSize: '24px' , marginTop : '10px' }}>{item.label}</h1>
                </div>
            })}
        </div>
    );
}
