import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaRegCopy, FaExclamationTriangle } from "react-icons/fa";
// import { useChainId } from "wagmi";

import { AppContext } from "../App";
import { ellipsisAddress } from "../utils/methods";
import { IoIosRefresh } from "react-icons/io";
import axios from "axios";

export default function MetricPage({ className }) {
    const {
        // user,
        SERVER_URL,
        currentProject,
        setLoadingPrompt,
        setOpenLoading,
    } = useContext(AppContext);
    // const chainId = useChainId();

    const [copied, setCopied] = useState({});
    const [metricData, setMetricData] = useState([]);
    const [totalProfitEth, setTotalProfitEth] = useState(0);

    // const disabled = !currentProject._id || !user || user.role === "admin";

    useEffect(() => {
        handleGetMetris();
    }, []);

    const copyToClipboard = async (key, text) => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(text);
            toast.success("Copied");
            setCopied({
                ...copied,
                [key]: true,
            });
            setTimeout(
                () =>
                    setCopied({
                        ...copied,
                        [key]: false,
                    }),
                2000
            );
        } else console.error("Clipboard not supported");
    };

    const handleGetMetris = async () => {
        setLoadingPrompt("Getting metrics...");
        setOpenLoading(true);
        try {
            const metrics = await axios.post(
                `${SERVER_URL}/api/v1/project/get-metrics`,
                {
                    projectId: currentProject._id,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "MW-USER-ID": localStorage.getItem("access-token"),
                    },
                }
            );
            console.log("handleGetMetris log - 1 : ", metrics);
            setMetricData(metrics.data);

            const totalProfit = metrics.data.reduce((sum, item) => {
                return sum + item.profit;
            }, 0);
            // console.log("handleGetMetris log - 2 : ", totalProfit);
            setTotalProfitEth(totalProfit);
        } catch (err) {
            console.log(err);
            toast.warn("Failed to get metrics data");
        }
        setOpenLoading(false);
    };

    return (
        // <div className={`${className} flex flex-col text-white rounded-[4px] border border-gray-highlight p-4 pb-3`}></div>
        <div
            className={`${className} flex flex-col text-white rounded-[4px] border border-gray-highlight p-4 pb-3`}
        >
            <div className="flex flex-col">
                <div className="flex items-start justify-between w-full h-auto">
                    <div className="flex items-center font-sans text-xs font-bold text-white">
                        METRIC
                        {currentProject.name && (
                            <div className="pl-1 font-bold uppercase text-green-normal">
                                {" "}
                                {currentProject.name
                                    ? `${currentProject.name}`
                                    : "No project"}
                            </div>
                        )}
                        {currentProject?.token?.address && (
                            <>
                                <div className="mx-2 text-gray-normal opacity-30">
                                    /
                                </div>
                                <div className="font-semibold text-gray-normal">
                                    {ellipsisAddress(
                                        currentProject?.token?.address
                                    )}
                                </div>
                                {copied["token_address"] ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-3.5 h-3.5 ml-2"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M5 13l4 4L19 7"
                                        />
                                    </svg>
                                ) : (
                                    <FaRegCopy
                                        className="w-3.5 h-3.5 ml-2 transition ease-in-out transform cursor-pointer active:scale-95 duration-100"
                                        onClick={() =>
                                            copyToClipboard(
                                                "token_address",
                                                currentProject?.token?.address
                                            )
                                        }
                                    />
                                )}
                                <a
                                    href={`https://${
                                        currentProject?.token?.chainId === 8453
                                            ? "basescan.org"
                                            : "etherscan.io"
                                    }/address/${
                                        currentProject?.token?.address
                                    }`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="w-3.5 h-3.5 object-contain ml-2"
                                        src="/assets/etherscan.png"
                                        alt="etherscan"
                                    />
                                </a>
                                <a
                                    href={`https://www.dextools.io/app/en/${
                                        currentProject?.token?.chainId === 8453
                                            ? "base"
                                            : "ether"
                                    }/pair-explorer/${
                                        currentProject?.token?.address
                                    }`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="w-3.5 h-3.5 object-contain ml-2"
                                        src="/assets/dextool.png"
                                        alt="dextools"
                                    />
                                </a>
                                <a
                                    href={`https://dexscreener.com/${
                                        currentProject?.token?.chainId === 8453
                                            ? "base"
                                            : "ethereum"
                                    }/${currentProject?.token?.address}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <img
                                        className="w-3.5 h-3.5 object-contain ml-2"
                                        src="/assets/dexscreener.png"
                                        alt="dexscreener"
                                    />
                                </a>
                            </>
                        )}
                    </div>
                    <div className="flex flex-row items-end gap-3 font-semibold text-gray-normal text-xs">
                        TOTAL PROFIT/LOSS(ETH) :
                        <p
                            className={`${
                                totalProfitEth >= 0
                                    ? "text-yellow-normal"
                                    : "text-green-normal"
                            }`}
                        >
                            {totalProfitEth.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 4,
                            })}
                        </p>
                        <button
                            className="pl-3 pr-4 h-button rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-100 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                            onClick={handleGetMetris}
                        >
                            <IoIosRefresh className="text-lg text-green-normal" />
                            Refresh
                        </button>
                    </div>
                </div>
                <div className="w-full overflow-visible font-sans">
                    <div className="flex flex-col w-full h-full mt-3 text-white bg-transparent bg-clip-border">
                        <div className="relative border border-gray-highlight">
                            <div
                                className={`h-[calc(100vh-184px)] overflow-y-auto`}
                            >
                                {(!currentProject.wallets ||
                                    currentProject.wallets.length === 0) && (
                                    <div className="absolute flex items-center justify-center gap-2 my-3 text-base font-bold text-center uppercase -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 text-gray-border">
                                        <FaExclamationTriangle className="text-sm opacity-50 text-green-normal" />{" "}
                                        No Wallet
                                    </div>
                                )}
                                <table className="w-full text-xs text-left">
                                    <thead className="text-gray-normal">
                                        <tr className="uppercase h-7 bg-[#262626] sticky top-0 z-10">
                                            <th className="w-8 text-center">
                                                #
                                            </th>
                                            <th className="text-center">
                                                Address
                                            </th>
                                            <th className="text-center">
                                                Cost of token
                                            </th>
                                            <th className="text-center">
                                                Bought amount
                                            </th>
                                            <th className="text-center">
                                                Sold amount
                                            </th>
                                            <th className="text-center">
                                                Profit/Loss(%)
                                            </th>
                                            <th className="text-center">
                                                Profit/Loss(ETH)
                                            </th>
                                            {/* <th className="text-center">
                                                Current ETH
                                            </th> */}
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-normal">
                                        {metricData?.length > 0 &&
                                            metricData.map((item, index) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        className={`${
                                                            index % 2 === 1 &&
                                                            "bg-[#ffffff02]"
                                                        } hover:bg-[#ffffff08] h-7`}
                                                    >
                                                        <td className="text-center">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-center">
                                                            <div className="flex items-center justify-center gap-1 font-sans antialiased font-normal leading-normal">
                                                                <p className="bg-transparent border-none outline-none">
                                                                    {ellipsisAddress(
                                                                        item.address
                                                                    )}
                                                                </p>
                                                                {copied[
                                                                    "wallet_" +
                                                                        index
                                                                ] ? (
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        className="w-3.5 h-3.5"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        stroke="currentColor"
                                                                        strokeWidth="2"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M5 13l4 4L19 7"
                                                                        />
                                                                    </svg>
                                                                ) : (
                                                                    <FaRegCopy
                                                                        className="w-3.5 h-3.5 transition ease-in-out transform cursor-pointer active:scale-95 duration-100"
                                                                        onClick={() =>
                                                                            copyToClipboard(
                                                                                "wallet_" +
                                                                                    index,
                                                                                item.address
                                                                            )
                                                                        }
                                                                    />
                                                                )}
                                                            </div>
                                                        </td>
                                                        <td className="text-center text-yellow-normal">
                                                            {
                                                                /* cost of token */
                                                                item.consumedETH.toLocaleString(
                                                                    undefined,
                                                                    {
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 4,
                                                                    }
                                                                )
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                /* bought amount */
                                                                item.boughtTokens.toLocaleString(
                                                                    undefined,
                                                                    {
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 4,
                                                                    }
                                                                )
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                /* sold amount */
                                                                item.soldTokens.toLocaleString(
                                                                    undefined,
                                                                    {
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 4,
                                                                    }
                                                                )
                                                            }
                                                        </td>
                                                        <td
                                                            className={`text-center ${
                                                                item.difference >=
                                                                0
                                                                    ? "text-gray-normal"
                                                                    : "text-green-normal"
                                                            }`}
                                                        >
                                                            {
                                                                /* profit/loss(%) */
                                                                item.difference.toLocaleString(
                                                                    undefined,
                                                                    {
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 4,
                                                                    }
                                                                )
                                                            }
                                                        </td>
                                                        <td
                                                            className={`text-center ${
                                                                item.profit >= 0
                                                                    ? "text-yellow-normal"
                                                                    : "text-green-normal"
                                                            }`}
                                                        >
                                                            {
                                                                /* profit/loss(ETH) */
                                                                item.profit.toLocaleString(
                                                                    undefined,
                                                                    {
                                                                        minimumFractionDigits: 0,
                                                                        maximumFractionDigits: 4,
                                                                    }
                                                                )
                                                            }
                                                        </td>
                                                        {
                                                            // <td className="text-center">
                                                            //     {
                                                            //         /* current ETH */
                                                            //         item.balance.toLocaleString(
                                                            //             undefined,
                                                            //             {
                                                            //                 minimumFractionDigits: 0,
                                                            //                 maximumFractionDigits: 4,
                                                            //             }
                                                            //         )
                                                            //     }
                                                            // </td>
                                                        }
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
