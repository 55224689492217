import { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useAccount, useChainId } from "wagmi";
import { ethers } from "ethers";
import BigNumber from "bignumber.js";

import { AppContext } from "../App";
import { useEthersSigner } from "../utils/provider";
import { isValidAddress } from "../utils/methods";

import tokenABI from "../abi/ITradingToken.json";
import routerABI from "../abi/IUniSwapV2Router02.json";
import factoryABI from "../abi/IUniswapV2Factory.json";

import MySidebar1 from "./MySidebar1";
import MySidebar2 from "./MySidebar2";

const UNISWAP_V2_ROUTER = {
    1: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    8453: "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24",
    5: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
    11155111: "0xC532a74256D3Db42D0Bf7a0400fEFDbad7694008",
};

export default function LiquidityPage({ className }) {
    const {
        setLoadingPrompt,
        setOpenLoading,
    } = useContext(AppContext);
    const chainId = useChainId();
    const { isConnected, address } = useAccount();
    // const provider = useEthersProvider(chainId);
    const signer = useEthersSigner(chainId);

    const [addBaseTokenAddress, setAddBaseTokenAddress] = useState("");
    const [addBaseTokenAmount, setAddBaseTokenAmount] = useState("");
    const [addEthAmount, setAddEthAmount] = useState("");
    const [removeBaseTokenAddress, setRemoveBaseTokenAddress] = useState("");
    const [removeLpTokenPercent, setRemoveLpTokenPercent] = useState("");
    const [removeLpTokenBalance, setRemoveLpTokenBalance] = useState("0");

    const updateBalance = async (tokenAddress, address, signer) => {
        try {
            console.log("Updating token balance...");
            const tokenContract = new ethers.Contract(tokenAddress, tokenABI, signer);
            const decimals = await tokenContract.decimals();
            const balance = await tokenContract.balanceOf(address);
            const amount = new BigNumber(balance.toString() + "e-" + decimals.toString());
            return amount.toString();
        }
        catch (err) {
            console.log(err);
            return "";
        }
    };

    const updateLpBalance = async (chainId, tokenAddress, address, signer) => {
        try {
            console.log("Updating LP balance...");
            const routerContract = new ethers.Contract(UNISWAP_V2_ROUTER[chainId], routerABI, signer);
            const WETH = await routerContract.WETH();
            const factoryAddr = await routerContract.factory();
            const factoryContract = new ethers.Contract(factoryAddr, factoryABI, signer);
            const lpAddr = await factoryContract.getPair(WETH, tokenAddress);
            const lpContract = new ethers.Contract(lpAddr, tokenABI, signer);
            const decimals = await lpContract.decimals();
            const balance = await lpContract.balanceOf(address);
            const amount = new BigNumber(balance.toString() + "e-" + decimals.toString());
            return amount.toString();
        }
        catch (err) {
            console.log(err);
            return "";
        }
    };

    useEffect(() => {
        if (addBaseTokenAddress !== "")
            updateBalance(addBaseTokenAddress, address, signer).then(amount => setAddBaseTokenAmount(amount));
        else
            setAddBaseTokenAmount("");
    }, [addBaseTokenAddress, signer, address]);

    useEffect(() => {
        if (removeBaseTokenAddress !== "")
            updateLpBalance(chainId, removeBaseTokenAddress, address, signer).then(amount => setRemoveLpTokenBalance(amount));
        else
            setRemoveLpTokenBalance("0");
    }, [removeBaseTokenAddress, chainId, signer, address]);

    const handleAddLiquidity = async () => {
        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (!isValidAddress(addBaseTokenAddress)) {
            toast.warn("Invalid token address!");
            return;
        }

        const amount0 = Number(addBaseTokenAmount.replaceAll(",", ""));
        if (isNaN(amount0) || amount0 < 0) {
            toast.warn("Invalid token amount!");
            return;
        }

        const amount1 = Number(addEthAmount.replaceAll(",", ""));
        if (isNaN(amount1) || amount1 < 0) {
            toast.warn("Invalid ETH amount!");
            return;
        }

        try {
            setLoadingPrompt("Checking pair...");
            setOpenLoading(true);

            const routerContract = new ethers.Contract(UNISWAP_V2_ROUTER[chainId], routerABI, signer);
            const WETH = await routerContract.WETH();
            const factoryAddr = await routerContract.factory();
            const factoryContract = new ethers.Contract(factoryAddr, factoryABI, signer);
            const pairAddr = await factoryContract.getPair(addBaseTokenAddress, WETH.toString());
            console.log(pairAddr);
            if (pairAddr === "0x0000000000000000000000000000000000000000") {
                setLoadingPrompt("Creating pair...");
                const tx = await factoryContract.createPair(addBaseTokenAddress, WETH.toString());
                if (tx)
                    await tx.wait();
            }

            const tokenContract = new ethers.Contract(addBaseTokenAddress, tokenABI, signer);
            const decimals = await tokenContract.decimals();
            const amount0Wei = new BigNumber(amount0.toString() + "e" + decimals.toString());
            const amount1Wei = new BigNumber(amount1.toString() + "e18");

            const allowance = await tokenContract.allowance(address, UNISWAP_V2_ROUTER[chainId]);
            if (amount0Wei.gt(new BigNumber(allowance.toString()))) {
                setLoadingPrompt("Approving...");
                const tx = await tokenContract.approve(UNISWAP_V2_ROUTER[chainId], ethers.MaxUint256);
                if (tx)
                    await tx.wait();
            }

            setLoadingPrompt("Adding liquidity...");
            const args = [addBaseTokenAddress, amount0Wei.toFixed(0), "0", "0", address, Math.floor(Date.now() / 1000) + 3600, { value: amount1Wei.toFixed(0) }];
            const tx = await routerContract.addLiquidityETH(...args);
            if (tx)
                await tx.wait();

            const balance = await updateBalance(addBaseTokenAddress, address, signer);
            setAddBaseTokenAmount(balance);

            toast.success("Added liquidity!");
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to add liquidity!");
        }
        setOpenLoading(false);
    };

    const handleRemoveLiquidity = async () => {
        if (!isConnected) {
            toast.warn("Please connect wallet!");
            return;
        }

        if (!isValidAddress(removeBaseTokenAddress)) {
            toast.warn("Invalid token address!");
            return;
        }

        const percent = Number(removeLpTokenPercent);
        if (isNaN(percent) || percent < 0 || percent > 100) {
            toast.warn("Invalid percent to remove liquidity!");
            return;
        }

        try {
            setLoadingPrompt("Removing liquidity...");
            setOpenLoading(true);

            const routerContract = new ethers.Contract(UNISWAP_V2_ROUTER[chainId], routerABI, signer);
            const WETH = await routerContract.WETH();
            const factoryAddr = await routerContract.factory();
            const factoryContract = new ethers.Contract(factoryAddr, factoryABI, signer);
            const lpAddr = await factoryContract.getPair(WETH, removeBaseTokenAddress);
            const lpContract = new ethers.Contract(lpAddr, tokenABI, signer);
            // const decimals = await lpContract.decimals();
            const balance = new BigNumber((await lpContract.balanceOf(address)).toString());
            const allowance = new BigNumber((await lpContract.allowance(address, UNISWAP_V2_ROUTER[chainId])).toString());
            if (allowance.lt(balance)) {
                const tx = await lpContract.approve(UNISWAP_V2_ROUTER[chainId], ethers.MaxUint256);
                if (tx)
                    await tx.wait();
            }

            const amount = balance.multipliedBy(new BigNumber(removeLpTokenPercent)).div(new BigNumber("100")).toFixed(0);
            const tx = await routerContract.removeLiquidityETHSupportingFeeOnTransferTokens(removeBaseTokenAddress, amount, "0", "0", address, Math.floor(Date.now() / 1000) + 3600);
            if (tx)
                await tx.wait();

            const lpBalance = await updateLpBalance(chainId, removeBaseTokenAddress, address, signer);
            setRemoveLpTokenBalance(lpBalance);

            toast.success("Removed liquidity!");
        }
        catch (err) {
            console.log(err);
            toast.warn("Failed to remove liquidity!");
        }
        setOpenLoading(false);
    };

    return (
        <div className="flex flex-row">
            <MySidebar1 />
            <div className={`${className} flex flex-col text-white font-sans gap-2 w-full p-8`}>
                <div className="w-full">
                    <div className="flex items-center justify-between w-full h-auto mb-5">
                        <div className="m-auto mt-10 text-xl font-medium text-white">
                            Add Liquidity
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 w-full rounded-b-[10px]">
                        <div className="">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Token Address<span className="pl-1 text-green-normal">*</span>
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                placeholder="Enter address"
                                value={addBaseTokenAddress}
                                onChange={(e) => setAddBaseTokenAddress(e.target.value)}
                            />
                        </div>
                        <div className="">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Token Amount<span className="pl-1 text-green-normal">*</span>
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                placeholder="Enter token amount"
                                value={addBaseTokenAmount}
                                onChange={(e) => setAddBaseTokenAmount(e.target.value)}
                            />
                        </div>
                        <div className="">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                ETH Amount<span className="pl-1 text-green-normal">*</span>
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-yellow-normal placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                placeholder="Enter ETH amount"
                                value={addEthAmount}
                                onChange={(e) => setAddEthAmount(e.target.value)}
                            />
                        </div>
                        <div className="relative flex h-full my-5 text-white bg-transparent justify-evenly bg-clip-border">
                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 inline-flex bg-green-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none"
                                onClick={handleAddLiquidity}
                            >
                                Add Liquidity
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-full">
                    <div className="flex items-center justify-between w-full h-auto mb-5">
                        <div className="m-auto mt-10 text-xl font-medium text-white">
                            Remove Liquidity
                        </div>
                    </div>
                    <div className="flex flex-col gap-4 w-full rounded-b-[10px]">
                        <div className="">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                Token Address<span className="pl-1 text-green-normal">*</span>
                            </div>
                            <input
                                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
                                placeholder="Enter address"
                                value={removeBaseTokenAddress}
                                onChange={(e) => setRemoveBaseTokenAddress(e.target.value)}
                            />
                        </div>
                        <div className="">
                            <div className="font-sans text-xs uppercase text-gray-normal">
                                % to remove liquidity<span className="pl-1 text-green-normal">*</span>
                            </div>
                            <div className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full py-2 mt-1">
                                <p className="text-xs text-right text-yellow-normal">Balance: {removeLpTokenBalance}</p>
                                <input
                                    className="w-full font-sans text-sm text-right text-white bg-transparent outline-none placeholder:text-gray-border h-button"
                                    placeholder="Enter % amount to remove liquidity"
                                    value={removeLpTokenPercent}
                                    onChange={(e) => setRemoveLpTokenPercent(e.target.value)} />
                                <div className="flex text-white text-[10px] gap-1 justify-end">
                                    <button className="px-2 py-1 rounded-[2px] bg-gray-highlight active:scale-95 transition duration-100 ease-in-out transform text-xxs text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed"
                                        onClick={() => setRemoveLpTokenPercent("25")}>
                                        25%
                                    </button>
                                    <button className="px-2 py-1 rounded-[2px] bg-gray-highlight active:scale-95 transition duration-100 ease-in-out transform text-xxs text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed"
                                        onClick={() => setRemoveLpTokenPercent("50")}>
                                        50%
                                    </button>
                                    <button className="px-2 py-1 rounded-[2px] bg-gray-highlight active:scale-95 transition duration-100 ease-in-out transform text-xxs text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed"
                                        onClick={() => setRemoveLpTokenPercent("75")}>
                                        75%
                                    </button>
                                    <button className="px-2 py-1 rounded-[2px] bg-gray-highlight active:scale-95 transition duration-100 ease-in-out transform text-xxs text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed"
                                        onClick={() => setRemoveLpTokenPercent("100")}>
                                        100%
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="relative flex h-full my-5 text-white bg-transparent justify-evenly bg-clip-border">
                            <button
                                className="font-sans text-xs font-medium text-center text-white uppercase px-6 h-10 rounded-[4px] justify-center items-center gap-2.5 inline-flex bg-green-normal active:scale-95 transition duration-100 ease-in-out transform focus:outline-none"
                                onClick={handleRemoveLiquidity}
                            >
                                Remove Liquidity
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <MySidebar2 />
        </div>
    );
}